<template>
  <div class="inline-flex items-center" v-if="instructors.length > 0">
    <div class="flex -space-x-3">
      <div
        v-for="instructor in instructors"
        :key="instructor.id"
        class="relative rounded-full bg-cover bg-center bg-white"
        @click.prevent="openInstructorsModal ? showInstructorBio(instructor) : null"
      >
        <PhotoIcon
          :class="{ 'cursor-pointer': clickable }"
          :instructor="instructor"
          :size="size"
          :title="instructor.name"
        />
      </div>
    </div>
    <div
      :class="{
        'line-clamp-1': props.inline
      }"
    >
      <span 
        class="ml-2" @click.prevent="openInstructorsModal ? showInstructorBio(instructors[0]) : null"
        :class="{ 'cursor-pointer': clickable }"
      >
        {{ instructors[0].name }}
      </span>
      <span v-if="instructors.length > 1"> + {{ instructors.length - 1 }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import PhotoIcon from '@/components/events/PhotoIcon.vue'
import InstructorModal from '@/components/events/InstructorModal.vue'
import { useModal } from '@/composables/useModal'

const modal = useModal();

const props = withDefaults(defineProps<{
  instructors: InstructorResponse[]
  size: string,
  openInstructorsModal?: boolean,
  clickable?: boolean,
  inline?: boolean
}>(), {
  openInstructorsModal: true,
  inline: false
})


const showInstructorBio = (instructor: InstructorResponse) => {
  modal.show(InstructorModal, {
    instructor
  })
}
</script>
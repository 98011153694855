<template>
  <div
    class="well-modal-content"
    :class="`well-modal-content--${props.size}`"
  >
    <div class="well-modal-content-header">
        <div class="well-modal-content-header__title">
          <slot name="title" />
        </div>
        <span
          class="well-modal-content-header__close"
          @click="emit('close')"
        >
          <WellIcon icon="x" class="cursor-pointer" :size="24" />
        </span>
    </div>
    <div class="well-modal-content_body">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import WellIcon from '@/components/common/WellIcon.vue'
import { useRouter } from 'vue-router'

const props = withDefaults(defineProps<{
  size?: 'small' | 'normal' | 'large'
}>(), {
  size: 'normal',
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const router = useRouter()

router.afterEach(() => {
  emit('close')
})
</script>

<style lang="scss" scoped>
.well-modal-content-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;     
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  &__close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 100;

    @screen md {
      right: 1rem;
      top: 1rem;
    }
  }

}

.well-modal-content {
  position: relative;
  width: 100%;
  background-color: var(--primary-contrast);
  min-height: 100vh;  
  margin: 0 auto;

  &_body {
    padding: 0 1rem 1rem;

    @screen md {
      padding: 0 2rem 2rem;
    }
  }

  @screen md {
    border-radius: 3px;
    min-height: auto;
  }
}

.well-modal-content {
  &--small {
    @screen md {
      max-width: 500px;
    }
  }

  &--normal {
    @screen md {
      max-width: 620px;
    }
  }

  &--large {
    @screen md {
      max-width: 800px;
    }
  }
}
</style>

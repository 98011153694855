  <template>
    <div 
      :class="{
        'w-full': props.size === 'small-full',
        'md:w-[250px]': props.size === 'small',
        'md:w-[352px]': props.size === 'large',
        'max-w-full w-full': props.size === 'full',
      }"
    >
      <header class="relative p-3 w-full aspect-square">
        <NuxtImg
          class="object-cover absolute inset-0 size-full"
          loading="lazy"
          :src="event.photo1"
        />
        <EventTypeTag
          class="relative"
          :event="event"
        />
      </header>
      <div class="flex flex-col my-5 gap-4">
        <h1
          class="line-clamp-2"
          :class="{
            'text-4xl': ['large', 'full'].includes(props.size),
            'text-3xl': ['small', 'small-full'].includes(props.size),
          }"
          v-tooltip.top="{value: event.event_name, showDelay: 1000, class: 'card-tooltip'}"
        >
          {{ event.event_name }}
        </h1>
      </div>
      <div class="flex flex-col items-start">
        <div class="flex flex-col items-start gap-1.5">
          <InstructorsStack 
            :openInstructorsModal="openInstructorsModal"
            :instructors="event.instructors" size="20px" 
            :inline="true"
          />
          <div class="flex flex-row mt-4 items-center flex-wrap">
            <EventDate :date="event.dateShort" />
            <span class="mx-3">•</span>
            <EventTime :time="event.time" />
          </div>
          <EventLocation :event="event" :inline="true" />
          <EventPrice v-if="!event.soldOut" :price="event.www_price" />
        </div>
        <NuxtLink 
          v-if="!event.soldOut"
          :to="`/events/${event.slug}`" 
          :target="props.openInNewWindow ? '_blank' : ''"
          class="text-primary underline mt-5"
        >
          <span v-if="event.isPassedEventRecordingAvailable">
            Watch Replay
          </span>
          <span v-else>
            Register Now
          </span>
        </NuxtLink>
        <NuxtLink
          v-else
          :to="`/events/${event.slug}`" 
          :target="props.openInNewWindow ? '_blank' : ''"
          class="bg-dark text-white text-sm px-3 py-1 rounded-md mt-5 inline-block shadow-md"
        >
          • SOLD OUT •
        </NuxtLink>    
      </div>
    </div>
  </template>

<script setup lang="ts">
  import EventDate from '@/components/events/EventDate.vue';
  import EventPrice from '@/components/events/EventPrice.vue';
  import EventLocation from '@/components/events/EventLocation.vue';
  import EventTime from '@/components/events/EventTime.vue';
  import InstructorsStack from '@/components/events/InstructorsStack.vue';
  import EventTypeTag from '@/components/events/EventTypeTag.vue';

  const props = withDefaults(defineProps<{
    event: EventDetails,
    size?: 'small' | 'small-full' | 'large' | 'full',
    openInstructorsModal?: boolean,
    openInNewWindow?: boolean
  }>(), {
    size: 'large',
    openInstructorsModal: true,
    openInNewWindow: false
  })
</script>

<style>
.card-tooltip {
  --p-tooltip-max-width: 300px;
  --p-tooltip-bg: var(--p-color-dark);
}
</style>
<template>
  <div
    class="mask-size-cover bg-current"
    :style="{
      maskImage: `url(/${props.type}/${props.icon}.svg)`,
      width: `${size}px`,
      height: `${size}px`,
      maskSize: '100%',
    }"
  ></div>
</template>

<script lang="ts" setup>
import { withDefaults } from 'vue'

const props = withDefaults(defineProps<{
  icon: string,
  size?: number,
  type?: 'untitled-ui-icons' | 'custom-icons',
}>(), {
  size: 20,
  type: 'untitled-ui-icons',
})
</script>

<style scoped>
.mask-size-cover {
  mask-repeat: no-repeat;
  mask-position: center;
}
</style>
<template>
  <div>
    <NuxtLoadingIndicator color="black" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ModalsContainer />
    <Toast />
  </div>
</template>

<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal';

const userStore = useUserStore()
const accessToken = useCookie('wellT')

if (accessToken.value) {
  await userStore.fetchUserInfo()
} else {
  userStore.student = null
}

useSeoMeta({
  title: 'Well Well Well',
  ogTitle: 'Well Well Well',
  description: 'World\'s go-to marketplace to experience what\'s hot in wellness.',
  ogDescription: 'World\'s go-to marketplace to experience what\'s hot in wellness.',
})
</script>

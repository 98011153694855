<template>
  <svg width="484" height="67" viewBox="0 0 484 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_376_4368)">
      <path d="M10.7274 66.0698L4.02001 9.47972C3.64244 5.75873 3.08719 2.32567 0 1.39542V0.930298H17.457V1.39542C14.9473 2.41426 14.5697 4.65129 15.0362 9.47972L19.4115 52.847L23.6092 13.4886L23.1428 9.47972C22.7652 5.75873 22.21 2.32567 19.1228 1.39542V0.930298H36.5798V1.39542C34.0701 2.41426 33.6037 4.65129 34.0701 9.47972L38.179 52.5591L40.6887 31.7171C43.4872 9.01459 40.511 2.41426 37.9791 1.39542V0.930298H50.1946V1.39542C47.6848 2.41426 45.2417 8.28369 43.2873 23.4556C42.6432 28.9485 42.0657 32.9353 41.7104 36.3019L39.556 53.2457C39.1784 56.5901 38.6232 60.5991 37.9791 66.0919H29.406L24.2755 19.9339L19.3227 66.0919H10.7496L10.7274 66.0698Z" fill="#101618"/>
      <path d="M76.7799 44.7626C76.6023 54.353 74.5367 67 66.4301 67C55.7915 67 50.1946 61.9722 50.1946 39.5577C50.1946 17.1431 56.2579 12.1154 64.8532 12.1154C73.4484 12.1154 77.2686 21.4178 77.2686 39.8456H60.4778C60.4778 58.1848 62.9875 65.4274 67.3851 65.4274C71.7827 65.4274 75.8693 56.7673 75.8693 44.7626H76.8021H76.7799ZM60.4556 38.5167H68.851C68.851 21.2185 68.2957 12.9349 64.942 12.9349C62.1436 12.9349 60.5666 21.0192 60.4556 38.5167Z" fill="#101618"/>
      <path d="M82.4657 10.6093C82.4657 6.88826 82.5545 3.5438 80.0448 2.50281V2.03769C84.7977 2.03769 91.7939 0 91.7939 0V57.4982C91.7939 61.2192 91.7939 64.5636 94.3036 65.5825V66.0476H80.0226V65.5825C82.5323 64.5636 82.4435 61.2192 82.4435 57.4982V10.6093H82.4657Z" fill="#101618"/>
      <path d="M99.5229 10.6093C99.5229 6.88826 99.6118 3.5438 97.102 2.50281V2.03769C101.855 2.03769 108.851 0 108.851 0V57.4982C108.851 61.2192 108.851 64.5636 111.361 65.5825V66.0476H97.0798V65.5825C99.5896 64.5636 99.5007 61.2192 99.5007 57.4982V10.6093H99.5229Z" fill="#101618"/>
      <path d="M137.036 66.0697L130.328 9.50181C129.951 5.78082 129.395 2.34776 126.308 1.41752V0.952393H143.765V1.41752C141.255 2.43636 140.878 4.67338 141.344 9.50181L145.72 52.8691L149.917 13.5107L149.451 9.50181C149.073 5.78082 148.518 2.34776 145.431 1.41752V0.952393H162.888V1.41752C160.378 2.43636 159.912 4.67338 160.378 9.50181L164.487 52.5812L166.997 31.7392C169.795 9.03669 166.819 2.43636 164.287 1.41752V0.952393H176.503V1.41752C173.993 2.43636 171.55 8.30578 169.595 23.4777C168.951 28.9706 168.374 32.9573 168.019 36.324L165.864 53.2678C165.487 56.6122 164.931 60.6212 164.287 66.114H155.714L150.584 19.956L145.631 66.114H137.058L137.036 66.0697Z" fill="#101618"/>
      <path d="M203.088 44.7626C202.91 54.353 200.845 67 192.738 67C182.1 67 176.503 61.9722 176.503 39.5577C176.503 17.1431 182.566 12.1154 191.161 12.1154C199.757 12.1154 203.577 21.4178 203.577 39.8456H186.786C186.786 58.1848 189.296 65.4274 193.693 65.4274C198.091 65.4274 202.177 56.7673 202.177 44.7626H203.11H203.088ZM186.742 38.5167H195.137C195.137 21.2185 194.582 12.9349 191.228 12.9349C188.43 12.9349 186.853 21.0192 186.742 38.5167Z" fill="#101618"/>
      <path d="M208.774 10.6093C208.774 6.88826 208.863 3.5438 206.353 2.50281V2.03769C211.106 2.03769 218.102 0 218.102 0V57.4982C218.102 61.2192 218.102 64.5636 220.612 65.5825V66.0476H206.331V65.5825C208.84 64.5636 208.752 61.2192 208.752 57.4982V10.6093H208.774Z" fill="#101618"/>
      <path d="M225.831 10.6093C225.831 6.88826 225.92 3.5438 223.41 2.50281V2.03769C228.163 2.03769 235.159 0 235.159 0V57.4982C235.159 61.2192 235.159 64.5636 237.669 65.5825V66.0476H223.388V65.5825C225.898 64.5636 225.809 61.2192 225.809 57.4982V10.6093H225.831Z" fill="#101618"/>
      <path d="M263.344 66.0697L256.636 9.50181C256.259 5.78082 255.704 2.34776 252.616 1.41752V0.952393H270.073V1.41752C267.564 2.43636 267.186 4.67338 267.653 9.50181L272.028 52.8691L276.226 13.5107L275.759 9.50181C275.382 5.78082 274.826 2.34776 271.739 1.41752V0.952393H289.196V1.41752C286.687 2.43636 286.22 4.67338 286.687 9.50181L290.795 52.5812L293.305 31.7392C296.104 9.03669 293.127 2.43636 290.595 1.41752V0.952393H302.811V1.41752C300.301 2.43636 297.858 8.30578 295.904 23.4777C295.26 28.9706 294.682 32.9573 294.327 36.324L292.172 53.2678C291.795 56.6122 291.24 60.6212 290.595 66.114H282.022L276.892 19.956L271.939 66.114H263.366L263.344 66.0697Z" fill="#101618"/>
      <path d="M329.374 44.7626C329.196 54.353 327.131 67 319.024 67C308.386 67 302.789 61.9722 302.789 39.5577C302.789 17.1431 308.852 12.1154 317.447 12.1154C326.043 12.1154 329.863 21.4178 329.863 39.8456H313.072C313.072 58.1848 315.582 65.4274 319.979 65.4274C324.377 65.4274 328.463 56.7673 328.463 44.7626H329.396H329.374ZM313.05 38.5167H321.445C321.445 21.2185 320.89 12.9349 317.536 12.9349C314.738 12.9349 313.161 21.0192 313.05 38.5167Z" fill="#101618"/>
      <path d="M335.06 10.6093C335.06 6.88826 335.149 3.5438 332.639 2.50281V2.03769C337.392 2.03769 344.388 0 344.388 0V57.4982C344.388 61.2192 344.388 64.5636 346.898 65.5825V66.0476H332.617V65.5825C335.126 64.5636 335.038 61.2192 335.038 57.4982V10.6093H335.06Z" fill="#101618"/>
      <path d="M352.139 10.6093C352.139 6.88826 352.228 3.5438 349.718 2.50281V2.03769C354.471 2.03769 361.468 0 361.468 0V57.4982C361.468 61.2192 361.468 64.5636 363.977 65.5825V66.0476H349.696V65.5825C352.206 64.5636 352.117 61.2192 352.117 57.4982V10.6093H352.139Z" fill="#101618"/>
      <path d="M379.88 47.8857V36.612H381.767V37.7858C382.323 36.9442 383.3 36.4569 384.433 36.4569C386.742 36.4569 388.253 38.2731 388.253 40.5323C388.253 42.7915 386.809 44.5634 384.41 44.5634C383.367 44.5634 382.389 44.1425 381.767 43.2344V47.9078H379.88V47.8857ZM384.055 38.0073C382.767 38.0073 381.723 38.8933 381.723 40.4215C381.723 41.9498 382.7 42.9465 384.099 42.9465C385.499 42.9465 386.343 41.8391 386.343 40.488C386.343 39.1369 385.477 38.0073 384.055 38.0073Z" fill="#101618"/>
      <path d="M393.561 36.4347C395.849 36.4347 397.803 38.2509 397.803 40.4879C397.803 42.7249 395.849 44.5411 393.561 44.5411C391.273 44.5411 389.341 42.7471 389.341 40.4879C389.341 38.2287 391.318 36.4347 393.561 36.4347ZM393.561 42.9243C394.894 42.9243 395.893 41.8611 395.893 40.4879C395.893 39.1147 394.894 38.0516 393.561 38.0516C392.228 38.0516 391.251 39.1368 391.251 40.4879C391.251 41.839 392.251 42.9243 393.561 42.9243Z" fill="#101618"/>
      <path d="M403.666 36.8112H405.088L406.687 41.7947L408.353 36.5897H410.396L407.598 44.3418H405.91L404.377 39.4469L402.823 44.3418H401.157L398.358 36.5897H400.379L402.067 41.7947L403.644 36.8112H403.666Z" fill="#101618"/>
      <path d="M418.969 43.0351C417.925 44.209 416.637 44.519 415.438 44.519C412.639 44.519 410.951 42.7028 410.951 40.488C410.951 38.2731 412.75 36.4126 415.216 36.4126C417.326 36.4126 419.302 37.7415 419.302 40.7316H412.839C412.839 41.9498 413.839 42.9243 415.527 42.9243C416.504 42.9243 417.215 42.57 417.881 41.9719L418.969 43.0351ZM412.95 39.4691H417.392C417.104 38.2731 416.037 37.9409 415.238 37.9409C414.438 37.9409 413.372 38.2731 412.95 39.4691Z" fill="#101618"/>
      <path d="M422.678 38.3173C422.945 36.9884 423.966 36.4347 424.81 36.4347C425.077 36.4347 425.232 36.479 425.454 36.5454V38.4059C425.166 38.2952 424.81 38.2509 424.566 38.2509C423.878 38.2509 422.723 38.6274 422.723 40.7758V44.3639H420.835V36.6119H422.7V38.3395L422.678 38.3173Z" fill="#101618"/>
      <path d="M434.028 43.0351C432.984 44.209 431.695 44.519 430.496 44.519C427.698 44.519 426.01 42.7028 426.01 40.488C426.01 38.2731 427.809 36.4126 430.274 36.4126C432.384 36.4126 434.361 37.7415 434.361 40.7316H427.898C427.898 41.9498 428.897 42.9243 430.585 42.9243C431.562 42.9243 432.273 42.57 432.939 41.9719L434.028 43.0351ZM428.009 39.4691H432.451C432.162 38.2731 431.096 37.9409 430.296 37.9409C429.497 37.9409 428.431 38.2731 428.009 39.4691Z" fill="#101618"/>
      <path d="M443.733 33.2675V44.364H441.845V43.1901C441.268 44.0318 440.313 44.519 439.158 44.519C436.87 44.519 435.36 42.7028 435.36 40.4437C435.36 38.1845 436.782 36.4347 439.18 36.4347C440.224 36.4347 441.201 36.8556 441.845 37.7637V33.2896H443.733V33.2675ZM439.558 42.9465C440.846 42.9465 441.868 42.0827 441.868 40.5322C441.868 38.9818 440.913 38.0073 439.491 38.0073C438.07 38.0073 437.27 39.1369 437.27 40.4658C437.27 41.7947 438.136 42.9465 439.558 42.9465Z" fill="#101618"/>
      <path d="M454.327 44.5411C453.195 44.5411 452.218 44.0539 451.662 43.2122V44.3861H449.774V33.2896H451.662V37.7636C452.284 36.8776 453.261 36.4347 454.305 36.4347C456.682 36.4347 458.125 38.2509 458.125 40.4436C458.125 42.6363 456.615 44.519 454.305 44.519L454.327 44.5411ZM453.994 38.0072C452.595 38.0072 451.64 39.0261 451.64 40.5322C451.64 42.0383 452.662 42.9464 453.95 42.9464C455.371 42.9464 456.238 41.839 456.238 40.4658C456.238 39.0925 455.416 38.0072 453.994 38.0072Z" fill="#101618"/>
      <path d="M464.899 36.5897H467.032L462.19 47.8634H460.324L461.901 43.8988L458.592 36.5897H460.724L462.856 41.9054L464.899 36.5897Z" fill="#101618"/>
      <path d="M388.053 54.8181L385.099 61.9279L382.145 54.8181H379.791L378.791 65.3609H381.146L381.701 58.9599L384.344 65.2502H385.854L388.497 58.9599L389.052 65.3609H391.407L390.407 54.8181H388.053Z" fill="#101618"/>
      <path d="M398.447 54.8181L394.361 65.3609H396.781L397.514 63.3897L401.668 63.4118L402.423 65.3609H404.866L400.735 54.8181H398.447ZM398.136 61.507L399.58 57.2988L400.979 61.507H398.114H398.136Z" fill="#101618"/>
      <path d="M415.327 63.4783C414.261 63.4783 413.839 62.5702 413.572 61.4628C414.971 61.0198 415.815 59.8017 415.815 58.2955C415.815 56.435 414.616 54.8403 412.439 54.8403H407.753V65.3831H410.085V61.64H411.484C411.595 62.7474 412.373 65.5603 414.883 65.5603C415.971 65.5603 416.548 65.206 417.237 64.6522L416.415 63.0354C416.126 63.3012 415.704 63.4783 415.349 63.4783H415.327ZM412.151 59.7574H410.085V56.7894H412.151C412.972 56.7894 413.483 57.3874 413.483 58.2734C413.483 59.1593 412.995 59.7574 412.151 59.7574Z" fill="#101618"/>
      <path d="M423.411 62.3044L421.079 54.8181H418.592L422.323 65.3609H424.477L428.231 54.8181H425.743L423.411 62.3044Z" fill="#101618"/>
      <path d="M431.362 65.3609H438.381V63.5226H433.694V61.1305H437.759V59.27H433.694V56.6786H438.381V54.8181H431.362V65.3609Z" fill="#101618"/>
      <path d="M444.577 54.8181H442.245V65.3609H449.375V63.5226H444.577V54.8181Z" fill="#101618"/>
      <path d="M456.282 54.6631C453.239 54.6631 450.774 57.0994 450.774 60.0895C450.774 63.0796 453.239 65.516 456.282 65.516C459.325 65.516 461.768 63.0796 461.768 60.0895C461.768 57.0994 459.303 54.6631 456.282 54.6631ZM456.282 63.3897C454.527 63.3897 453.106 62.0165 453.106 60.0895C453.106 58.1626 454.55 56.7894 456.282 56.7894C458.014 56.7894 459.458 58.1626 459.458 60.0895C459.458 62.0165 458.014 63.3897 456.282 63.3897Z" fill="#101618"/>
      <path d="M471.029 61.3741C471.029 62.836 470.074 63.5669 468.986 63.5669C467.898 63.5669 466.965 62.836 466.965 61.3741V54.8181H464.633V61.3741C464.633 64.0763 466.632 65.5381 469.008 65.5381C471.385 65.5381 473.384 64.0984 473.384 61.3741V54.8181H471.052V61.3741H471.029Z" fill="#101618"/>
      <path d="M481.202 59.2257L480.091 58.8271C479.025 58.4727 478.958 57.9632 478.958 57.6975C478.958 57.0109 479.514 56.7229 480.246 56.7229C480.691 56.7229 481.424 56.8337 481.868 57.919L483.778 56.9666C483.134 55.4383 481.868 54.6631 480.246 54.6631C478.181 54.6631 476.693 55.837 476.693 57.7418C476.693 58.6056 477.048 60.0674 479.38 60.8647L480.535 61.2634C481.535 61.6178 481.757 61.9943 481.757 62.393C481.757 63.1682 481.002 63.4783 480.18 63.4783C479.203 63.4783 478.536 62.9689 478.27 62.0386L476.182 62.4373C476.404 64.3864 478.225 65.5381 480.269 65.5381C482.134 65.5381 484.067 64.5636 484.067 62.3487C484.067 61.3742 483.622 59.9788 481.246 59.2257H481.202Z" fill="#101618"/>
      </g>
      <defs>
      <clipPath id="clip0_376_4368">
      <rect width="484" height="67" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <div class="flex flex-row items-center gap-2">
    <WellIcon icon="marker-pin-02" />
    <span
      class="flex-1"
      :class="{
        'line-clamp-1': props.inline
      }"
    >
      {{ isInPerson && props.event.location_address ? props.event.location_address : props.event.typeTag }}
    </span>
  </div>
</template>

<script setup lang="ts">
import WellIcon from '@/components/common/WellIcon.vue'

const props = defineProps<{
  event: EventDetails,
  size?: number,
  inline?: boolean
}>()

const isInPerson = computed(() => {
  return props.event.event_type === 'studio_event'
})

</script>

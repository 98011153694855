<template>
  <div class="flex flex-row items-center gap-2">
    <WellIcon icon="currency-dollar-circle" :size="size" />    
    <span v-if="!isFree">{{ formattedPrice }} USD</span>
    <span v-else>Free</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import WellIcon from '@/components/common/WellIcon.vue'

const props = defineProps<{
  price: number | string
  size?: number
}>()

const isFree = computed(() => {
  const numPrice = typeof props.price === 'string' ? parseFloat(props.price) : props.price
  return numPrice === 0
})

const formattedPrice = computed(() => {  
  const numPrice = (typeof props.price === 'string' ? parseFloat(props.price) : props.price) || 0
  return numPrice.toLocaleString('en-US', { style: 'decimal', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
})
</script>


import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/messaging';


console.log(process.env.VUE_APP_FIREBASE_API_KEY, process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,);
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID,
};

firebase.initializeApp(firebaseConfig);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('firebase', firebase);
});
<template>
  <div
    :style="{
      width: size,
      height: size,
      minWidth: size,
      minHeight: size
    }"
    class="relative overflow-hidden rounded-full"
  >
    <NuxtImg
      :src="imgUrl"
      :title="title"
      loading="lazy"
      class="absolute w-full h-full object-cover"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'


const props = withDefaults(defineProps<{
  instructor: InstructorResponse
  size?: string,
  title?: string
}>(), {
  size: '25px',
  title: '',
})

const imgUrl = computed(() => {
  return (
    props.instructor.image?.thumbnail ||
    props.instructor.image?.embedded_image_url ||
    props.instructor.image ||
    props.instructor.profile_photo ||
    props.instructor.community_profile_photo ||
    '/assets/images/default-instructor-photo.png'
  )
})
</script>
<template>
  <WellModalContent
    class="pt-10"
    @close="close"
  >
    <Login
      class="mx-auto"
      @close="close"
    />
  </WellModalContent>
</template>

<script setup lang="ts">
import WellModalContent from '~/components/common/WellModalContent.vue'
import Login from '~/components/auth/Login.vue'

const attrs = useAttrs()

const close = () => {
  attrs._close()
}
</script>
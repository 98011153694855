<template>
  <footer class="border-t border-dark py-10">
    <div class="px-4 lg:px-10 flex items-center justify-between">
      <div>
        <div class="flex items-center gap-2">
          <a href="https://www.heymarvelous.com/terms" target="_blank">Terms</a>
          <span>|</span>
          <a href="https://www.heymarvelous.com/privacy" target="_blank">Privacy Policy</a>
          <span class="hidden md:block">|</span>
          <span class="hidden md:block">© Marvelous 2024. All rights reserved.</span>
        </div>
        <span class="block md:hidden">© Marvelous® 2024</span>
      </div>

      <div class="cursor-pointer">
        <WellIcon icon="arrow-narrow-up" :size="24" @click="scrollToTop" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import WellIcon from '@/components/common/WellIcon.vue'

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
</script>
<template>
  <div class="min-h-screen flex flex-col">
    <PortalNavBar class="border-b border-dark" />
    <div class="flex items-center justify-center flex-1 flex-col max-w-[850px] mx-auto w-full">
      <div class="p-4 text-center flex flex-col items-center py-14">
        <h1 class="text-4.5xl mb-8">404 Error</h1>
        <p class="mb-12">Looks like the URL is incorrect or invalid. Sorry about that!</p>
        <button class="button-dark-outlined button-lg flex items-center gap-3" @click="$router.push('/')">
          BACK TO HOME
        </button>
      </div>
      <hr class="w-full border-dark" />
      <div class="w-full px-4 md:px-0 py-12">
        <EventsSlider 
          :events="events" 
          size="small"
          class="mt-3"
        >
          <template #title>
            <span class="inline-flex gap-2 items-center">
              <WellIcon icon="calendar" />
              UPCOMING EVENTS
            </span> 
          </template>
        </EventsSlider>
      </div>
    </div>
    <PortalFooter />
    <AppTabBar class="lg:hidden" />
  </div>
</template>

<script setup lang="ts">
import PortalNavBar from '@/components/layout/PortalNavBar.vue'
import PortalFooter from '@/components/layout/PortalFooter.vue'
import EventsSlider from '@/components/events/EventsSlider.vue'
import AppTabBar from '@/components/layout/AppTabBar.vue'
import WellIcon from '@/components/common/WellIcon.vue'
import { useApi } from '@/composables/useApi'

const { fetchEvents } = useApi()

const events = ref<EventResponse[]>([])

const { data: eventsRes, error: eventsError } = await useAsyncData('events', () => fetchEvents({page: 1}))
events.value = eventsRes.value.results

</script>

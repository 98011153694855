import { defineStore } from "pinia";
import Cookies from 'js-cookie';

interface State {
  student: StudentResponse | null;
}

export const useUserStore = defineStore('user', {
  
  state: ():State => {
    return {
      student: null,
    };
  },
  getters: {
    isLoggedIn: (state) => !!state.student,
  },
  actions: {
    async login(email: string, password: string) {
      const loginResponse = await useApi().login(email, password);
      const { key } = loginResponse;
      this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async logout() {
      this.setAccessToken(null);
      this.student = null;
    },
    async fetchUserInfo() {
      const { data, error } = await useApi().fetchUserInfo();
      if (error.value) {
        console.error('Error fetching user info:', error.value)
      } else {
        const { student, user_email } = data.value
        this.student = {...student, email: user_email}
      }
    },
    async loadUserInfo() {
      const { student, user_email } = await useApi().getUserInfo();
      this.student = {...student, email: user_email};
    },
    setAccessToken(token: string | null) {
      const accessToken = useCookie('wellT', {
        // expires in 1 year
        maxAge: 60 * 60 * 24 * 365,
      });
      accessToken.value = token;
      refreshCookie('wellT');
      if(process.client) {
        if (token) {
          Cookies.set('wellT', token, { expires: 365 });
        } else {
          Cookies.remove('wellT');
        }
      }
    },
    async loginWithFacebook(
      token: string
    ): Promise<LoginResponse> {
      const loginResponse = await useApi().facebookLogin(token);
      const { key } = loginResponse;
      this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async loginWithGoogle(
      token: string
    ): Promise<LoginResponse> {
      const loginResponse = await useApi().googleLogin(token);
      const { key } = loginResponse;
      this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
  },
});


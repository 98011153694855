<template>
  <div>
    <span class="flex items-center gap-2">
      <img src="/images/marvelous-brand-icon.svg" alt="Marvelous" class="w-5 h-5" />
      <span class="uppercase">
        Login with Marvelous
      </span>
    </span>
    <h1 class="text-4.5xl mt-4">
      Welcome back! Sign in with your Marvelous account details.
    </h1>
    <!--
    <span class="mt-4 inline-block">
      Don't have an account? <a class="underline" href="/signup">Register now</a>
    </span>
    -->
    <div class="mt-4 flex flex-col gap-4">
      <Button class="gap-2 text-xl justify-start" outlined size="large" severity="secondary" @click="facebookLogin">
        <img src="/images/facebook.svg" alt="Facebook" class="w-5 h-5" />
        Sign in with Facebook
      </Button>
      <Button class="justify-start gap-2 text-xl" outlined size="large" severity="secondary" @click="googleLogin">
        <img src="/images/google.svg" alt="Google" class="w-5 h-5" />
        Sign in with Google
      </Button>
      <span class="flex items-center gap-2">
        <span class="w-full h-[1px] bg-dark"></span>
        <span class="mx-4">or</span>
        <span class="w-full h-[1px] bg-dark"></span>
      </span>
    </div>
    <Form
      class="mt-4 flex flex-col gap-4"
      @submit="login"
      ref="form"
      v-slot="{ meta, errors }"
    >
      <div>
        <InputText
          class="w-full"
          label="Email"
          type="email"
          v-model="email"
          placeholder="Email address"
          size="large"
        />
        <div class="text-red-500 mt-1" v-if="errors.email">{{ errors.email }}</div>
      </div>
      <div>
        <InputText
          class="w-full"
          label="Password"
          type="password"
          v-model="password"
          placeholder="Password"
          size="large"
        />
        <div class="text-red-500 mt-1" v-if="errors.password">{{ errors.password }}</div>
      </div>
      <div class="flex justify-between items-center">
        <div>
          <!--
          <NuxtLink to="/forgot-password" class="underline">
            Forgot your password?
          </NuxtLink>
          -->
        </div>
        <Button
          type="submit"
          size="large"
          :disabled="inProgress"
        >
          LOG IN
        </Button>
      </div>
      <div class="text-red-500" v-if="errors.non_field_errors">{{ errors.non_field_errors }}</div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { Form } from 'vee-validate';
import { ref } from 'vue'
import { useUserStore } from '@/stores/user'

const emit = defineEmits(['close'])

const firebase = useNuxtApp().$firebase
const form = ref()
const email = ref('')
const password = ref('')
const inProgress = ref(false)
const userStore = useUserStore()

const login = async () => {
  inProgress.value = true
  try {
    await userStore.login(email.value, password.value)
    emit('close')
  } catch (error) {
    console.log(error)
    form.value.setErrors(error?.data)
  } finally {
    inProgress.value = false
  }
}

const facebookLogin = async () => {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    await useUserStore().loginWithFacebook(result.credential.accessToken);
    emit('close')
  } catch (error) {
    console.log(error)
  }
}

const googleLogin = async () => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    await useUserStore().loginWithGoogle(result.credential.accessToken);
    emit('close')
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
  <div>
    <div
      class="flex justify-between items-center mb-6"
      :class="{ 'lg:mr-12': props.size == 'large' }"
    >
      <span class="flex items-center">
        <slot name="title"></slot>
      </span>
      <div class="flex gap-4 md:gap-8">
        <div
          class="flex items-center gap-2 custom-swiper-nav cursor-pointer"
          :class="`custom-swiper-prev-${uuid}`"
        >
          <WellIcon icon="arrow-left" :size="24" />
          <span class="hidden md:block" v-if="!hideNavigationLabel">
            PREVIOUS
          </span>
        </div>
        <div
          class="flex items-center gap-2 custom-swiper-nav cursor-pointer"
          :class="`custom-swiper-next-${uuid}`"
        >
          <span class="hidden md:block" v-if="!hideNavigationLabel">
            NEXT
          </span>
          <WellIcon icon="arrow-right" :size="24" />
        </div>
      </div>
    </div>
    <Swiper
      :slidesPerView="1"
      :spaceBetween="16"
      :breakpoints="{
        768: {
          slidesPerView: 'auto',
          spaceBetween: spaceBetween,
        }
      }"
      :modules="[SwiperNavigation]"
      @swiper="setSwiper"
      :navigation="{
        nextEl: `.custom-swiper-next-${uuid}`,
        prevEl: `.custom-swiper-prev-${uuid}`,
      }"
    >
      <SwiperSlide
        class="slide md:flex-1"
        :class="`slide--${props.size}`"
        v-for="(event, $index) in props.events"
        :key="$index"
      >
      <NuxtLink :to="`/events/${event.slug}`">
        <EventCard
            :event="event"
            :size="props.size"
          />
        </NuxtLink>
      </SwiperSlide>
      <!-- Add this new SwiperSlide -->
      <SwiperSlide
        class="slide view-all-slide flex-1"
        :class="`slide--${props.size}`"
        v-if="canSwipe"
      >
        <NuxtLink to="/experiences" class="view-all-link">
          View All Events
        </NuxtLink>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { defineProps, useId } from 'vue';
import EventCard from '@/components/events/EventCard.vue';
import WellIcon from '@/components/common/WellIcon.vue';

const uuid = useId()
const swiper = ref<SwiperType | null>(null)

const setSwiper = (swiperControl: SwiperType) => {
  swiper.value = swiperControl
}

const props = withDefaults(defineProps<{
  events: EventResponse[],
  size?: 'small' | 'large',
}>(), {
  size: 'large',
})

const hideNavigationLabel = computed(() => props.size == 'small')

const spaceBetween = computed(() => props.size === 'small' ? 50 : 100)

// False if swiper is not initialized
const canSwipe = computed(() => swiper.value? !(swiper.value?.isBeginning && swiper.value?.isEnd) : false);
</script>

<style lang="scss" scoped>
.slide {
  &--small {
    @apply md:w-[250px] md:max-w-[250px];
  }
  &--large {
    @apply md:w-[352px] md:max-w-[352px];
  }
}
@media (min-width: 768px) {
  .slide {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: var(--dark);
    }
    &:last-child::after {
      background-color: transparent;
    }
    &--small::after {
      right: -25px;
    }
    &--large::after {
      right: -50px;
    }
  }
}

.custom-swiper-nav.swiper-button-disabled {
  visibility: hidden;
}

/* Add these new styles */
.view-all-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--p-surface-500);
  // min-width: 330px;
  &.slide--small {
    min-height: 506px;
    @apply min-w-[330px] md:min-w-[250px];
  }
  &.slide--large {
    min-height: 676px;
    @apply min-w-[330px] md:min-w-[352px];
  }
}


.view-all-link {
  text-decoration: underline;
  color: var(--p-primary-contrast-color);
  font-weight: bold;
}
</style>
<template>
  <WellModalContent
    class="instructor-modal-content"
    @close="$attrs._close()"
    size="large"
  >
    <div class="flex items-center gap-5">
      <PhotoIcon
        :instructor="props.instructor"
        size="60px"
      />
      <div class="flex flex-col">
        <h3 class="text-4.5xl">
          {{ props.instructor.name }}
        </h3>
      </div>
    </div>
    <div
      class="flex flex-col mt-6"
      v-if="props.instructor.bio_new"
    >
      {{ props.instructor.bio_new }}
    </div>
    <hr class="w-full mt-8 border-dark" />
    <section class="w-full mt-4 py-4">
      <EventsSlider 
        :events="similarEvents" 
        size="small"
        class="mt-3"
      >
        <template #title>
          <span class="inline-flex gap-2 items-center">
            <WellIcon icon="calendar" />
            INSTRUCTOR’S EVENTS
          </span> 
        </template>
      </EventsSlider>
    </section>

  </WellModalContent>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useApi } from '@/composables/useApi'
import PhotoIcon from '@/components/events/PhotoIcon.vue'
import WellModalContent from '~/components/common/WellModalContent.vue'
import WellIcon from '@/components/common/WellIcon.vue'

const props = defineProps<{
  instructor: InstructorResponse
}>()

const { fetchInstructorEvents } = useApi()

const similarEvents = ref<EventResponse[]>([])

onMounted(async () => {
  const similarEventsResponse = await fetchInstructorEvents(props.instructor.id)
  console.log(similarEventsResponse)
  similarEvents.value = similarEventsResponse
})

</script>

<style lang="scss">

.instructor-modal-content {
  padding-top: 2rem;
}

</style>

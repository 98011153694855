import { default as _91event_id_93Yf2CyKRReCMeta } from "/tmp/build_74022ce4/pages/events/[event_id].vue?macro=true";
import { default as experienceskil0WWjssUMeta } from "/tmp/build_74022ce4/pages/experiences.vue?macro=true";
import { default as indexhfaU2boRKOMeta } from "/tmp/build_74022ce4/pages/index.vue?macro=true";
import { default as events_45slider_45examplePw4Mw6bzdCMeta } from "/tmp/build_74022ce4/pages/widgets/events-slider-example.vue?macro=true";
import { default as events_45sliderE6ADFLOyoUMeta } from "/tmp/build_74022ce4/pages/widgets/events-slider.vue?macro=true";
export default [
  {
    name: "events-event_id",
    path: "/events/:event_id()",
    component: () => import("/tmp/build_74022ce4/pages/events/[event_id].vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    meta: experienceskil0WWjssUMeta || {},
    component: () => import("/tmp/build_74022ce4/pages/experiences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_74022ce4/pages/index.vue")
  },
  {
    name: "widgets-events-slider-example",
    path: "/widgets/events-slider-example",
    meta: events_45slider_45examplePw4Mw6bzdCMeta || {},
    component: () => import("/tmp/build_74022ce4/pages/widgets/events-slider-example.vue")
  },
  {
    name: "widget-events-slider",
    path: "/widgets/events-slider",
    meta: events_45sliderE6ADFLOyoUMeta || {},
    component: () => import("/tmp/build_74022ce4/pages/widgets/events-slider.vue")
  }
]
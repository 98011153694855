<template>
  <div class="bg-white rounded-t-xl sticky bottom-0 shadow-t-md z-50">
    <div class="flex items-center">
      <NuxtLink
        to="/"
        class="tab-item"
      >
        <WellIcon icon="home-01" :size="16" />
        <span>Home</span>
      </NuxtLink>
      <NuxtLink
        class="tab-item"
        to="/experiences"
      >
        <WellIcon icon="calendar-heart-01" :size="16" />
        <span>Experiences</span>
      </NuxtLink>
      <a
        class="tab-item"
        target="_blank"
        href="https://wellwellwell.blog/"
      >
        <WellIcon icon="book-open-01" :size="16" />
        <span>Blog</span>
      </a>
      <div
        class="tab-item"
        v-if="!userStore.isLoggedIn"
        @click="openLoginModal"
      >
        <WellIcon icon="log-in-04" :size="16" />
        <span>Login</span>
      </div>
      <div
        class="tab-item"
        v-if="userStore.isLoggedIn"
        @click="userStore.logout"
      >
        <WellIcon icon="log-out-01" :size="16" />
        <span>Logout</span>
      </div>
      <a
        target="_blank"
        href="https://heymarvelous.com/well-well-well"
        class="tab-item"
      >
        <WellIcon icon="stars-02" :size="16" />
        <span>Host</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import WellIcon from '~/components/common/WellIcon.vue'
import LoginModal from '~/components/auth/LoginModal.vue'
import { useModal } from '@/composables/useModal'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const modal = useModal()

const openLoginModal = () => {
  modal.show(LoginModal, {})
}
</script>


<style scoped>
.tab-item {
  font-size: 10px;
  @apply flex flex-col items-center text-dark-gray py-4 px-1 flex-1 justify-center gap-1;
  &.router-link-active {
    @apply text-dark;
  }
}
</style>
